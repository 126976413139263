import React, {useEffect, useState} from "react";
import { graphql } from "gatsby";

import Layout from "../components/global/Layout";
import BlogBanner from "../components/global/bloglist/BlogBanner";
import BlogGrid from "../components/global/bloglist/BlogGrid";
import FaqBar from "../components/global/bloglist/FaqBar";
import GetInTouch from "../components/global/forms/GetInTouch";

import { parseSeoFromQuery } from "../components/global/SEO";
import BlogFilter from "../components/global/bloglist/BlogFilter";

export default function Blog(props) {
  // Not ordered as in CMS! =/
  const data = props?.data?.markdownRemark?.frontmatter;
  const posts = props?.data?.allMarkdownRemark?.edges.map(edge => ({
    ...edge?.node?.frontmatter,
  }));
  const tags = Array.from([...new Set(posts.filter(post => post?.tags != null).flatMap(post => post?.tags)), data?.filterAllOption]);
  const [selectedTag, setSelectedTag] = useState(data?.filterAllOption);
  const [gridPosts, setGridPosts] = useState(posts);
  const page = props?.pageContext?.page;
  useEffect(() => {
    if(selectedTag !== null && selectedTag !== data?.filterAllOption){
      var filteredPosts = posts.filter(x => x.tags.includes(selectedTag));
      setGridPosts(filteredPosts);
    }

    if(selectedTag === data?.filterAllOption) {
      setGridPosts(posts);
    }
  }, [selectedTag]);
  return (
    <Layout {...parseSeoFromQuery(props.data)} page={page}>
      <BlogBanner {...data?.blogBanner} />
      <FaqBar {...data?.faqBar} />
      <BlogFilter selectedTag={selectedTag} setSelectedTag={setSelectedTag} tags={tags} filterLabel={data?.filterText} />
      <BlogGrid posts={gridPosts} {...data?.blogBanner} />
      <GetInTouch {...data?.getInTouch} />
    </Layout>
  );
}

export const query = graphql`
  query ($page: String, $locale: String) {
    markdownRemark(
      frontmatter: { page: { eq: $page }, locale: { eq: $locale } }
    ) {
      frontmatter {
        ...seo
        ...getInTouch
        blogBanner {
          subheader
          title
          readmore
        }

        faqBar {
          boldTitle
          title
          faqButtonText
        }
        blogTags
        filterText
        filterAllOption
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { page: { eq: "blogpost" }, locale: { eq: $locale } }
      }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          frontmatter {
            draft
            title
            subheading
            date
            authorName
            image
            slug
            tags
          }
        }
      }
    }
  }
`;
