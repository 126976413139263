import React from "react"
import logo from "../../../assets/images/tp-logo.svg"
import FadeLeftAnimation from "../../common/FadeLeftAnimation";

const BlogBanner = props => {
  const { subheader, title } = props
  return (
    <div className="contact-banner">
      <div className="container">
        <div className="row align-items-center main-banner-content ">
          <div className="col-12 col-lg-10">
            <div className="text-white innerpage-title">
              <FadeLeftAnimation>
                <h4 className="">{subheader}</h4>
              </FadeLeftAnimation>
              <FadeLeftAnimation>
                <h1 className="text-primary">{title}</h1>
              </FadeLeftAnimation>
            
            </div>
          </div>
          <div className="col-12 col-lg-2">
            <img src={logo} alt="service" className="blog-child-img overflow-hidden" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogBanner
