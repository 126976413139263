import React from "react"
import Select from 'react-select'

const customStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: '#1763F5',
        color: '#fff',
        fontFamily: 'Arial, sans-serif',
        width: '150px',
        minHeight: 'fit-content'
    }),
    option: (provided) => ({
        ...provided,
        color: '#000',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#fff',
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: state.isFocused ? '#fff' : '#fff',
        transition: '#fff',
    })
};
const BlogFilter = ({tags, setSelectedTag, selectedTag, filterLabel}) => {
    const handleChange = (selectedOption) => {
        setSelectedTag(selectedOption.value);
    };

    const options = tags.map(tag => ({value: tag, label: tag}));

    return <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '40px'
    }}>
        <label htmlFor="tag-filter" style={{
            marginBottom: '0',
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'PoppinsMedium',
            fontWeight: '500',
            fontStyle: 'normal',
            fontSize: '1.35rem',
            paddingRight: '10px'
        }}>{filterLabel}:</label>
        <Select
            styles={customStyles}
            options={options}
            onChange={handleChange}
            value={options.find(option => option.value === selectedTag)}
            id="tag-filter"
        />
    </div>
}

export default BlogFilter;