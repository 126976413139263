import { Link } from 'gatsby'
import React from 'react'
import {buildPathPrefix} from "../../../utils/path-utils";
import {useLocale} from "../../../utils/use-country-location";


const FaqBar = (props) => {
  const { boldTitle, title, faqButtonText } = props;
    const locale = useLocale();
    const linkPrefix = buildPathPrefix(locale);

    return (
    <section className="info-investor-area bg-primary">
        <div className="container">
          <div className="row">
            <div className='col-8 flex-center-vertically align-items-md-end'>
                <span className='d-table-cell ps-2'>
                    <b>{boldTitle}</b> {title}
                </span>
            </div>
            <div className='col-4 blogFaqButton'>
                <Link className="default-btn-black font-medium text-uppercase" to={`${linkPrefix}/faq/`}>
                    {faqButtonText} <span></span>
                </Link>
            </div>
          </div>
        </div>
      </section>
    )
}

export default FaqBar 