import React from 'react';

const BadgeContainer = ({ badges }) => {
    const containerStyle = {
        position: 'absolute',
        bottom: '5px',
        right: '5px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '5px'
    };

    const badgeStyle = {
        display: 'inline-block',
        padding: '5px 10px',
        color: '#fff',
        backgroundColor: '#1763F5',
        fontSize: '12px',
        fontWeight: 'bold',
        marginLeft: '5px',
    };

    return (
        <div style={containerStyle}>
            {badges.map((text, index) => (
                <div key={index} style={badgeStyle}>{text}</div>
            ))}
        </div>
    );
};

export default BadgeContainer;