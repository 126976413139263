import React from "react";

//using this sparingly, trying to reduce the DOM elements in Locations.js since it has an 'excessive DOM size' warning from Lighthouse
export default function useDeviceDetect() {
  const [isMobile, setMobile] = React.useState(false);
  const [width, setInnerWidth] = React.useState(0);

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setInnerWidth(window.innerWidth);
    });

    if (typeof window.innerWidth !== "undefined") {
      setInnerWidth(window.innerWidth);
    }
    setMobile(width <= 1199);
  }, [width]);

  return { isMobile };
}
