import React from "react"
import BadgeContainer from "../../common/BadgeContainer";

const BlogGrid = ({ posts, readmore }) => {
  return (
    <React.Fragment>
        <div className="container pb-5 overflow-hidden">
          <div className="row px-md-4 mx-auto blog-grid">
              {posts?.filter(post => !post.draft).map(post => {
                  const path = `${post.slug}/`;
                  return (
                      <div className="col-12 col-lg-6 pb-5 px-3" >
                          <a href={path} aria-label="Blog Post URL" >
                              <div className="single-solutions-box cover-blog" style={{backgroundImage: `url(${post.image})`, position: "relative", display: "inline-block"}}>
                                  {post.tags?.length > 0 && (<BadgeContainer badges={post.tags}/>) }
                              </div>
                              <h5 className="blogtitle text-black font-medium pe-5"><span>{post.title}</span></h5>
                              <h4 className="readmore text-black slideblack2_text pe-5 pt-4 mt-auto">{readmore}</h4>
                          </ a>
                      </div>
                  )})}
            </div>
        </div>
    </React.Fragment>
  )
}

export default BlogGrid
