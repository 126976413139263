import ReactWOW from "react-wow";
import React from "react";
import useDeviceDetect from "./DetectMobile";

const FadeLeftAnimation = (props) => {
    const { isMobile } = useDeviceDetect();
    // Implementing this for any animation above the fold
    // for mobile devices
    // the loading time for mobile is a bit poor and therefore tweaking these for better experience

    let delay = isMobile ? "200ms" : ".1s";
    let duration = isMobile ? "250ms" : "1s";

    return (
        <ReactWOW delay={delay} animation="fadeInLeft" duration={duration}>
            {props.children}
        </ReactWOW>
    )
}

export default FadeLeftAnimation;